.reportAppBody {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    -webkit-print-color-adjust: exact;
}
.AppHeader{
    -webkit-print-color-adjust: exact;
}

.reportProjectContainer {
    background-color: #494949;
    position: absolute;
    top: 5%;
    display: flex;
    border-radius: .5%;
    width: 90%;
    opacity: 90%;
    flex-direction: column;
}

.reportProjectImageContainer {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
    align-self: center;
    display: block;
}
.reportAuditTitle{
    display: flex;
    overflow: 'visible';
    font-size: 'x-large';
    font-weight: 'bolder'
}
.reportProjectDescriptionContainer{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 95%;
    height: max-content;
    padding: 5%;
}
.reportProjectFieldDescription{
        align-self: center;
    padding: .5rem;
    width: 100%;
    height: 10ch;
    background-color: #282c34;
    flex: 1;
    color: white;
}
.reportProjectImage {
    display: block;
    max-height: 300px;
    max-width: 100%;
    height: auto;
    flex: 1;


}
.reportProjectReturnContainer{
    padding: 2rem;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
}
.reportProjectSection {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    flex-wrap: wrap;
}

.reportProjectSectionContainer {
    display: flex;
    justify-content: flex-start;
    break-inside: avoid-page;
    width: 100%;
    flex-wrap: wrap;
}

.reportProjectSectionTitle {
    color: white;
    flex: 1;
    font-weight: bold;
    padding: 1rem;
    font-size: xx-large;
    background: linear-gradient(90deg, #00b3ba 0%, #00ba8d 100%);
}

.reportProjectPePcContainer {
    padding: 1rem;
    display: flex;
    flex-direction: column;
}


.reportProjectReturnSubTitle{
    
    border-radius: 1%;
    flex: 1;
    width: 100%;
    text-align: center;
    height: 100%;
    padding: 1rem;
    background: linear-gradient(90deg, #00b3ba 0%, #00ba8d 80%);
    border-radius: 3rem;

    color: white;
    font-weight: bold;
    font-size: x-large;
}
.reportProjectWasteTitle{
    flex: 1;
    color: white;
    font-weight: bold;
    font-size: x-large;
}
.reportProjectWasteSubTitle{
    border-radius: 1%;
    flex: 1;
    width: 100%;
    text-align: center;
    height: 100%;
    padding: 1rem;
    background: linear-gradient(90deg, #00b3ba 0%, #00ba8d 80%);
    
    font-weight: bolder;
    margin-bottom: 5%;
    font-size: larger;
    color: white;
}
.reportProjectFieldContainer {
    display: flex;
    padding: 1rem;
    width: fit-content;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.canvasContainerContainer {
    padding: 1rem;
    background-color: #282c34;
    border-radius: 1%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.canvasContainer {
    background-color: #494949;
    border-radius: .5%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

@media screen and (max-width: 500px) {

    .reportMatrix {
        overflow-x: scroll;

    }
}

@media screen and (min-width: 800px) {

    .reportMatrix {
        justify-content: center;
    }
}

.reportMatrix {
    padding-bottom: 5rem;
    display: flex;
    width: auto;
    height: 100%;
}

.reportIntroductionContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 3%;
}

.reportProjectFieldTitle {
    flex: 1;
    color: white;

    font-size: large;
}

.reportProjectFieldInput {
    border-radius: 1%;
    flex: 1;
    width: 100%;
    text-align: center;
    min-height: 30pt;
    height: 100%;
    padding: .5rem;
    background-color: #282c34;
    color: white;
}

.reportProjectFieldDate {
    border-radius: 1%;
    padding: .5rem;
    width: fit-content;
    flex: 1;
    color: white;
    background-color: #282c34;
    text-align: center;
}

.reportProjectFieldRadioContainer {
    width: 100%;
    display: flex;
}

.reportProjectFieldRadioOptionContainer {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;

}

.reportProjectFieldRadio {
    flex: 1;
    scale: 2;
}

.coolTablesContainer {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 5%;
}

.coolTable {
    flex: 1;
}

.fileInputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
    padding: 5%;
}