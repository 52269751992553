@media print {
    html {
        height: 0;
        }
    table { display: block; page-break-before:always; page-break-inside:auto;  }
    tr    { page-break-inside:avoid; page-break-after:auto }
    thead { display:table-header-group }
    tfoot { display:table-footer-group }

    .reportAppBody {
        display: block;
        overflow: visible;
        height: 100%;
        width: 100%;
    }
    .reportProjectWasteTitle{
        flex: 1;
        color: #282c34;
        font-weight: bold;
        font-size: x-large;
    }
    .reportProjectWasteSubTitle{
        border-radius: 1%;
        flex: 1;
        width: 100%;
        text-align: center;
        height: 100%;
        padding: 1rem;
        background: white;
        font-weight: bolder;
        margin-bottom: 5%;
        font-size: larger;
        color: #282c34;
    }

    .AppHeader {
        display: flex;
        justify-content: center;
        align-self: center;
        width: 100%;
    }

    .AppHeaderButtonsContainer {
        display: none;
    }
    .HeaderLogo {
        margin-left: 0%;}
    .App {
        width: 100%;
        display: flex;
        overflow: visible;
        height: 100%;
    }
    .returnBorder{
        display: flex;
        background-color: #282c34;
        border-radius: 3rem;
        padding: 2px;

    }
    .reportProjectReturnSubTitle{
    
        border-radius: 1%;
        flex: 1;
        width: 100%;
        text-align: center;
        height: 100%;
        padding: 1rem;
        background: white;
        border-radius: 3rem;
        color: black;
        font-weight: bold;
        font-size: x-large;
    }

    .reportProjectContainer {
        display: flex;
        position: absolute;
        top: 0%;
        border-radius: 0%;
    background-color: white;
        width: 100%;
        opacity: 100%;
        flex-direction: column;
    }

    .reportProjectImage {
        display: flex;
        align-self: center;
        height: auto;
        width: auto;
        margin-bottom: 10%;
        page-break-after: always;
        page-break-inside: auto;
        
    }

    .reportProjectSection {
        display: flex;
        height: auto;
        width: auto;
        background-color: white;
        justify-content: space-between;
        align-items: center;
    }

    .reportProjectSectionContainer {
        display: block;
        justify-content: flex-start;
        background-color: white;
        width: 100%;
        flex-wrap: wrap;        
    }

    .reportProjectSectionTitle {
        color: #282c34;
        flex: 1;
        font-weight: bold;
        padding: 1rem;
        font-size: xx-large;
        background: white;
    }

    .reportProjectPePcContainer {
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }


    @media screen and (max-width: 500px) {

        .reportProjectAuditTitle {
            font-size: small !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }
    }

    .reportProjectAuditTitle {

        color: #282c34;
        flex: 1;
        display: flex;
        width: 100%;
        font-weight: normal;
        justify-content: center;
    }

    .reportProjectAudit {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .reportProjectFieldContainer {
        display: block;
        padding: 1rem;
        width: 33%;
        flex-wrap: wrap;
        page-break-inside: avoid;
        flex-direction: column;
    }

    .canvasContainerContainer {
        padding: 1rem;
        width: 100%;
        background-color: white;
        border-radius: 1%;
        display: flex;
        flex-direction: column;
    }

    .canvasContainer {
        width: 100%;
        background-color: #494949;
        border-radius: 1%;
        display: flex;
        flex-direction: column;
    }

    .reportMatrix {
        page-break-inside: avoid;
        display: block;
        align-self: center;
        width: auto;
        height: auto;
    }

    .reportIntroductionContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 3%;
    }

    .reportProjectFieldTitle {
        flex: 1;
        color: #282c34;
        font-size: large;
    }

    .reportProjectFieldInput {
        border-radius: 1%;
        border: #282c34 solid 3px;
        padding: .5rem;
        display: flex;
        background-color: white;
        display: block;
        color: #282c34;
    }


    .reportProjectFieldRadioContainer {
        width: 100%;
        display: flex;
    }

    .reportProjectFieldRadioOptionContainer {
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: flex-start;

    }
    .reportProjectFieldDate {
        
        border-radius: 1%;
        border: #282c34 solid 3px;
        padding: .5rem;
        width: fit-content;
        flex: 1;
        color: #282c34;
        background-color: white;
        text-align: center;
    }
    .reportProjectFieldRadio {
        flex: 1;
        scale: 2;
    }

    .coolTablesContainer {
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 5%;
    }

    .coolTable {
        flex: 1;
    }

    .fileInputContainer {
        display: flex;
        justify-content: center;
        align-items: center;        
        border-radius: 1%;
        border:#282c34 solid 20vh;
        background-color: #282c34;
        padding: 5%;
    }
}