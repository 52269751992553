@@ -1,142 +0,0 @@

.editAppBody {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  overflow-y: scroll;
  position: relative;
}

.editProjectContainer {
  background-color: #494949;
  position: absolute;
  top: 5%;
  display: flex;
  border-radius: 0.5%;
  width: 90%;
  opacity: 90%;
  flex-direction: column;
}
.editProjectImage {
  display: block;
  max-height: 300px;
  max-width: 100%;
  height: auto;
  align-self: center;
}
.editProjectSection {
  padding: 2rem;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;
}

.editProjectSectionContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.editProjectSectionTitle {
  color: white;
  flex: 1;
  font-weight: bold;
  padding: 1rem;
  font-size: xx-large;
  background: linear-gradient(90deg, #00b3ba 0%, #00ba8d 100%);
}
.editProjectPePcContainer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.editProjectFieldContainer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.canvasContainerContainer {
  padding: 1rem;
  width: 100%;
  background-color: #282c34;
  border-radius: 1%;
  display: flex;
  flex-direction: column;
}
.canvasContainer {
  width: 100%;
  background-color: #494949;
  border-radius: 1%;
  display: flex;
  align-self: center;
}

.editProjectFieldTitle {
  flex: 1;
  color: white;
  font-size: large;
}
.editProjectFieldInput {
  border-radius: 1%;
  padding: 0.5rem;
  background-color: #282c34;
  flex: 1;
  color: white;
}

.editProjectFieldDate {
  border-radius: 1%;
  padding: 0.5rem;
  width: 100%;
  color: white;
  display: flex;
  background-color: #282c34;
  align-items: center;
  justify-content: center;
}

.editProjectFieldRadioContainer {
  width: 100%;
  display: flex;
}
.editProjectFieldRadioOptionContainer {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}
.editProjectFieldRadio {
  flex: 1;
  scale: 2;
}
.coolTablesContainer {
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 5%;
}
.coolTable {
  flex: 1;
}

.fileInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  padding: 5%;
}
