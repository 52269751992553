.addAppBody {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  position: relative;
  overflow-y: scroll;
}

.projectSection {
  padding: 2rem;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;
}

.projectSectionContainer {
  display: flex;
  justify-content: flex-start;
}

.projectSectionTitle {
  color: white;
  flex: 1;
  font-weight: bold;
  padding: 1rem;
  font-size: xx-large;
  background: linear-gradient(90deg, #00b3ba 0%, #00ba8d 100%);
}

.projectPePcContainer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.projectFieldContainer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.canvasContainerContainer {
  padding: 1rem;
  background-color: #282c34;
  border-radius: 1%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.canvasContainer {
  overflow: hidden;
  background-color: #494949;
  border-radius: .5%;
  display: flex;
  align-self: center;
}

.projectFieldTitle {
  flex: 1;
  color: white;
  font-size: large;
}

.projectFieldInput {
  border-radius: 1%;
  padding: 0.5rem;
  background-color: #282c34;
  flex: 1;
  color: white;
}

.projectDescriptionContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 95%;
  height: max-content;
  padding: 5%;
}
.projectFieldDescription {
  align-self: center;
  padding: 0.5rem;
  width: 100%;
  height: 10ch;
  background-color: #282c34;
  flex: 1;
  color: white;
}

.projectFieldDate {
  border-radius: 1%;
  padding: 0.5rem;
  width: 100%;
  color: white;
  display: flex;
  background-color: #282c34;
  align-items: center;
  justify-content: center;
}

.projectFieldRadioContainer {
  width: 100%;
  display: flex;
}

.projectFieldRadioOptionContainer {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.projectFieldRadio {
  width: 50%;
  height: 50%;
}

.coolTablesContainer {
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 5%;
}

.coolTable {
  flex: 1;
}

.fileInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  padding: 5%;
}
