.App {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.AppBody {
  display: flex;
  width: 100%;
  background-color: #364042;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
}

.MuiPaper-root {
  border-radius: 5px !important ;
}

.AppHeader {
  display: flex;
  background: linear-gradient(90deg, #00b3ba 0%, #00ba8d 100%);
  height: 15%;
  justify-content: space-between;
  align-items: center;
}

.HeaderLogo {
  margin-left: 25%;
  display: flex;
  width: auto;
}

@media screen and (max-width: 500px) {
  .AppHeaderButtonsContainer {
    overflow-x: auto;
    width: 100%;
  }
}

.AppHeaderButtonsContainer {
  display: flex;
  flex-direction: row;
  min-width: 30%;
  max-width: 60%;
  justify-content: space-between;
  align-items: center;
}

.AppHeaderButton {
  flex: 1;
  color: white;
  padding: 0vh 2vh;
  opacity: 0%;
  margin: 0;
  font-weight: bold;
}

.AppBodyHrefSeparator {
  padding-inline: 100px;
}

.AppHrefContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  width: 90%;
  flex-wrap: wrap;
  padding: 10%;
  font-weight: bold;
  font-size: 15pt;
}

.AppBodyContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  max-width: 110vh;
  font-weight: bold;
  font-size: 15pt;
}

.contactContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.AppBodyTitle {
  max-width: 600px;
  color: white;
  font-size: 23pt;
  font-weight: bolder;
}

.AppBodySubTitle {
  margin-top: 5%;
  color: white;
  font-size: 15pt;
}

.AppBodyHref {
  color: white;
  margin-bottom: 1pt;
  font-size: 15pt;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.AppIntroSection {
  width: 90%;

  min-height: min-content;
  padding-top: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.AppContactSection {
  background-color: rgb(40, 44, 52, 0.9);
  border-radius: 1%;
  min-height: min-content;
  width: 90%;
  padding: 10pt;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.AppSection {
  width: 90%;
  min-height: min-content;
  padding-top: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.failedLogin {
  background-color: #8e1818;
  text-align: center;
  color: white;
  border-radius: 1rem;
}

.loginContainerContainer {
  display: flex;
  padding: 1rem;
  border-radius: 10%;
  background-color: rgb(40, 44, 52, 0.9);
}
.contactFormContainer {
  width: 100%;
  background-color: rgb(73, 73, 73, 0.5);
  display: flex;
  flex-direction: column;
}
.loginContainer {
  display: flex;
  flex-direction: column;
  min-width: 40vh;
  padding: 1rem;
  border-radius: 7%;
  background-color: rgb(73, 73, 73, 0.5);
}
.contactFieldContainer {
  padding: 5%;
  display: flex;
  border-radius: 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fieldContainer {
  padding: 5%;
  display: flex;
  border-radius: 5%;
  flex-direction: column;
}

.loginButton {
  opacity: 90%;
}
.submitButton {
  opacity: 90%;
  width: 100%;
}
.fieldTitle {
  padding-bottom: 5%;
  font-weight: bold;
  flex: 1;
  color: white;
}

.fieldInput {
  flex: 1;
  border-radius: 2%;
  padding: 2%;
  font-size: 10pt;
  background-color: rgb(40, 44, 52, 0.9);
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
